.menu-flags {
    display: inline-block;
}

.menu-flags-item {
    display: inline-block;
}

.menu-flags, .menu-flags-item {
    .selected--flag--option {
        padding-top: $flag-select-padding-top;
    }
    svg {
        height: $flag-select-img-height;
        width: $flag-select-img-width;
        top: $flag-select-img-top;
    }
    .flag-options {
        max-height: $flag-select-options-max-height;
        margin-top: $flag-select-options-margin-top;
    }
    .menu-flags-btn {
        background: transparent;
        border: none;
        color: #4d4d4d;
        padding: 0 8px;
    }
}