// add other styles
body {
  font-family: 'Roboto', sans-serif;
}

.btn-light {
  color: $gray-700;
  border: 1px solid $gray-200;
}

.btn-link {
  padding: 0px;
}

// Synchronisation table
.synchronisation {
  font-weight: 400;
  font-size: 32px;
  text-align: center;

  .queued {
    color: #639cac;
  }

  .underway {
    color: #2e79e2;
  }

  .completed {
    color: #74b87c;
  }

  .legend {
    font-size: 12px;
    color: #7e8b94;
  }
}

.synchronisation-header {
  margin-top: -6em;
  margin-left: -2em;
  font-size: 12px;

  .title {
    color: #639cac;
  }

  .subtitle {
    margin-left: 1rem;
    color: #7e8b94;
  }
}

.dropdown-toggle {
  background: transparent;
  padding: 0px;
  border: 0;
  vertical-align: 0%;
  // padding: 0.5rem 1rem;
}

.text-sm {
  font-size: 12px;
}

.popover {
  max-width: 100%;
}

.nav-tabs .nav-link {
  color: #20a8d8;
}

.diagnostic-log-container {
  height: 60vh;
  max-height: 60vh;
  overflow-y: scroll;
  border: 1px lightgray solid;
  padding: 0 10px;
}

.diagnostic-log {
  font-size: 0.7em;
  border-bottom: 1px lightgray solid;
  padding: 5px 0;
}

.diagnostic-log :hover {
  background: #dddad3;
}

.diagnostic-log-msg {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 80%;
  display: inline-block;
  flex: 1;
}

.diagnostic-log-details {
  font-size: 0.7em;
  padding-left: 2.5em;
  border-bottom: 1px lightgray solid;
}

.diagnostic-log-details p {
  margin: unset;
}

.sync-filters-left-col-inner {
  margin-right: 10px;
  padding-right: 15px;
  padding-left: 15px;
}

.sync-filters-left-col-inner .input-group {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.sync-filters-source-table-div {
  position: relative;
  height: 57vh;
  overflow: auto;
  display: block;
  border: 1px lightgray solid;
}

.sync-filters-source-table-div .table {
  margin-top: 10px;
  table-layout: fixed;
}

.sync-filters-source-table-div .table td {
  padding: 2px 10px;
  cursor: pointer;
}

.sync-filters-source-table-div .table tr td:first-child {
  width: 10px;
}

.sync-filters-right-col #expression {
  margin-bottom: 10px;
  width: 100%;
}

.sync-filters-right-col #tree {
  margin-bottom: 10px;
  width: 100%;
  height: 45vh;
  background-color: #fff;
}

.sync-filters-right-col #deleteFilter {
  margin-right: 10px;
}

.sync-filters-action {
  height: 50px;
}

.sync-progress {
  width: 80%;
  height: 3em;
}

.tooltip {
  pointer-events: none;
}

.breadcrumb {
  padding: 0.75rem 1rem;
}

.form-control, .form-control:focus {
  background-color: #fff;
}

.btn-success, .btn-primary {
  color: #fff;
}
