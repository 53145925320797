// scss-lint:disable NestingDepth, SelectorDepth
.dock {
  
  .dock-header {
    background: $dock-header-bg;
 
    .title {
      margin-left: 1rem;
      margin-top: 6rem;
      margin-bottom: 1rem;
      font-size: 32px;
      color: $white;
      font-weight: bold;
    }

    .close-btn  {
      @extend .title;
      font-size: 20px;
    }
  }

  .dock-body {
    width: auto;
    margin: 2rem;
  }

  .dock-footer {
    height: 30px;
    vertical-align: bottom;
  }
}